import React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {  RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { authentication, checkParameterExists, getUserDocument, newAcc } from '../firebase-config';
import {  signOut } from "firebase/auth";
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
const Login = () => {
    const [cookie, setCookie] = useCookies(['Anno']);
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const nevigate = useNavigate();
    const onSubmit = data => {
        
            requestOTP();
          
    };
    const [loginBtn,setLoginBtn ] = useState("Sign in");
    const watchPhone = watch('phone');
    const watchPassword = watch('password');
    
    const signout = () => {
        signOut(authentication).then(() => {
            console.log("Sign-out successful."); 
            }).catch((error) => {
            // An error happened.
            });
            
    }
    useEffect(() => {
        signout();
        
           
    },[]);

    const genReCaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('reCapcha', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              
            }
          }, authentication);
    }
    const requestOTP = () => {
        var number = watchPhone;
        var pass = watchPassword;
        //setLoginBtn("Verify OTP");        
        console.log(number);    
        var val = checkParameterExists(number);
        val.then(function(data){
            //console.log(data);
            if(!data.uid){
                alert("Data not found! please register");
                nevigate('/register');
            }else{
                console.log(data.data);
                if(data.data.password === pass){
                    console.log("Pass Matched");
                    const json = {name: data.data.name, address: data.data.address, uid: data.uid,phone:data.data.phone};
                            setCookie('Anno', JSON.stringify(json), { path: '/' });
                            nevigate('/');
                }
            }
            
        })  
    };
    /*
    const verifyOTP = ()=> { 
        let otp = watchOTP;

        if(otp.length === 6){
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log(user.uid);
                var temp = newAcc(user.uid);
                console.log(temp);
                temp.then(function(value){
                    if(value) {
                        signout();
                    nevigate('/register');
                    } else {
                        const data = getUserDocument(user.uid);
                        data.then(function(values){
                            //console.log(values);
                            const json = {name: values.name, address: values.address, uid: user.uid};
                            setCookie('Anno', JSON.stringify(json), { path: '/' });
                            nevigate('/');
                        })
                        

                          
                    }
                })
                
                // ...
              }).catch((error) => {
                console.log("User couldn't sign in (bad verification code?)"); 
                console.log(error);
                // ...
              });
        }
    } */

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl shadow-rose-600/40 ring-2 ring-red-600 lg:max-w-xl">
                <h1 className="text-3xl font-bold text-center text-red-700 uppercase decoration-wavy">
                   Login in
                </h1>
                <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-2">
                        <label
                            htmlFor="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Phone Number
                        </label>
                        <input
                            type="number"
                            {...register("phone", {required: 'This field is required', min:{value:6000000000 , message: 'Enter valid phone number'} , max:{value:9999999999 , message: 'Enter valid phone number'}})}
                            className="block w-full px-4 py-2 mt-2 text-red-700 bg-white border rounded-md focus:border-red-400 focus:ring-red-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                              <p>{errors.phone?.message}</p>

                    </div>
                    
                    <div className="mb-2">
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-800">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                {...register("password", { required: 'This field is required' , minLength: { value: 8, message: 'Password must be at least 8 characters long' } })}
                                className="block w-full px-4 py-2 mt-2 text-red-700 bg-white border rounded-md focus:border-red-400 focus:ring-red-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                            {/* Show/Hide password toggle button */}
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600 focus:outline-none"
                            >
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 8v.01M6 8v.01" />
                                    </svg>
                                )}
                            </button>
                        </div>
                        <p>{errors.password?.message}</p>
                    </div>

                    <div className="mt-6">
                       
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transhtmlForm bg-red-700 rounded-[20px] hover:bg-red-600 focus:outline-none focus:bg-red-600">
                           {loginBtn}
                        </button>
                        
                    </div>
                    <div  id='reCapcha'></div> 
                </form>
                
                <p className="mt-8 text-xs font-light text-center text-gray-700">
                    {" "}
                    Don't have an account?{" "}
                    <Link
                        to="/register"
                        className="font-medium text-red-600 hover:underline"
                    >
                        Sign up
                    </Link>
                </p>
            </div>
        </div>
  )
}

export default Login