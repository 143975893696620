import vector1 from "../assets/vector1.jpg";
import vector2 from "../assets/vector2.jpg";
import vector3 from "../assets/vector3.jpg";
import vector4 from "../assets/vector4.jpg";
import vector5 from "../assets/vector5.jpg";
export const ApiSlides = [
    {
      src: vector1,
      content: {
        h2: "Amazing Deals this Summer",
        p: "Upto 40% off",
      },
      background: ' bg-[#c6e400]'
    },
    {
      src: vector2,
      active: true,
      content: {
        h2: "Amazing healthy food",
        p: "Upto 40% off",
      },
      background: ' bg-[#ffd028]'
    },
    {
      src: vector3,
      content: {
        h2: "Amazing Deals this puja",
        p: "Upto 50% off",
      },
      background: ' bg-[#c6e400]'
    },
    {
      src: vector4,
      content: {
        h2: "Amazing Deals this Summer",
        p: "Upto 40% off",
      },
      background: ' bg-[#c6e400]'
    },
    {
      src: vector5,
      content: {
        h2: "Amazing Deals this Summer",
        p: "Upto 40% off",
      },
      background: ' bg-[#c6e400]'
    },
    ]