import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img6 from "../assets/img6.jpg";
import img7 from "../assets/img7.jpg";
import img8 from "../assets/img8.jpg";

export const ApiCategories =[
    {
        title : 'Rice & Roti',
        src : img8,
    },
    {
        title: 'Daal',
        src : img7,
    },
    {
        title: 'Bhaja/Fry',
        src : img6,
    },
    
    {
        title: 'Seddho-batna',
        src : img1,
    },
    {
        title: 'Saag',
        src : img2,
    },
    {
        title: 'Torkari/Sabzi',
        src : img4,
    },
    {
        title : 'Jhol/Curry',
        src : img3,
    },
    {
        title : 'Aachar',
        src : img5,
    },
]