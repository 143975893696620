import React from 'react';
import image from '../assets/vector4.jpg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack, MenuOutlined } from '@material-ui/icons';
import Sidedrawer from '../components/Sidedrawer';
const WhyAnno = () => {
  const navigate = useNavigate();
    const [drawer,setDrawer] = useState(false);
    const handleOnClose = () =>{setDrawer(false);}
  return (
    <div className='w-full '> 
    <div className="w-full  flex text-lg md:rounded-t-xl text-bold shadow-lg bg-red-600 text-white text-[20px]">  
        <MenuOutlined onClick={() => setDrawer(true)}  style={{fontSize : '30px'}}/>
        <span className='grow m-auto w-full text-center ' > How it works </span>
        <ArrowBack onClick={() => navigate(-1)}  style={{fontSize : '30px'}}/>
        </div>
        
       <img src={image} className='w-full' alt="" srcset="" />
        <Sidedrawer visible={drawer} onClose={handleOnClose}/>
     </div>
    
  )
}

export default WhyAnno